import React, { useContext, useEffect, useState } from 'react';
import { Alert, LoadingBar, useStyles2 } from '@grafana/ui';
import _ from 'lodash';

import { InstanceContext } from 'api/InstanceProvider';
import { getStyles } from 'components/BlogPosts/Kubernetes/KubernetesSelfHostedMetrics.styles';

import { TableOfContents } from 'components/TableOfContents/TableOfContents';
import { TableOfContentsDropdown } from 'components/TableOfContents/TableOfContentsDropdown';
import { ComponentKey } from 'types/ComponentKey';
import { useDocsPluginVisited } from 'hooks/useDocsPluginVisited';
import { QueryError } from 'types/QueryError';
import Markdown from 'markdown-to-jsx';
import markdownText from 'data/markdown/tutorials/oss-migration.md';


export const MigrateOss = () => {
  const styles = useStyles2(getStyles);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 901);
  const { instanceData, error } = useContext(InstanceContext);



  useDocsPluginVisited(
    ComponentKey.TutorialPluginSupabaseHasMadeItToBottom,
    _.get(instanceData, 'orgId', null)
  );

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 901);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const headings = [
    { value: 'Step 1', label: 'Plan Your Migration' },
    { value: 'Step 2', label: 'Prerequisites' },
    { value: 'Step 3', label: 'Upgrade Grafana OSS to the latest version' },
    { value: 'Step 4', label: 'Migrate Grafana resources' },
  ];

  if (error) {
      return (
          <div>
              <Alert title="Error" severity="error">
                  {(error as QueryError)?.data?.message}
              </Alert>
          </div>
      );
  }

  const replaceEntities = (text: string) => {
    return text.replace(/&#39;/g, "'").replace(/&quot;/g, '"');
  };
  const processedMarkdownText = replaceEntities(markdownText);


  return (
    <div className={styles.canvas}>
      <div className={styles.mainContent}>
        {!instanceData && <LoadingBar width={0} />}
        <TableOfContents headings={headings} />
        <div className={styles.container}>
          {isMobile && <TableOfContentsDropdown headings={headings} />}
          <div>
            <Markdown>
            {processedMarkdownText}

            </Markdown>
          </div>
        </div>
      </div>
    </div>
  );
};
