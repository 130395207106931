import React, { useEffect, useState, useContext } from 'react';
import { Alert, useStyles2, LoadingBar, RadioButtonGroup } from '@grafana/ui';
import _ from 'lodash';

import { debianAmd, debianArm, logsSetup, nodeExporterSetup } from 'components/BlogPosts/Linux/snippets';
import { TableOfContents } from 'components/TableOfContents/TableOfContents';
import { getStyles } from 'components/BlogPosts/Linux/LinuxNode.styles';
import { CopyToClipboard } from 'components/CopyToClipboard/CopyToClipboard';
import { TableOfContentsDropdown } from 'components/TableOfContents/TableOfContentsDropdown';
import { AccessPolicyToken } from 'components/AccessPolicyToken/AccessPolicyToken';
import { QueryError } from 'types/QueryError';
import { ComponentKey } from 'types/ComponentKey';
import { useDocsPluginVisited } from 'hooks/useDocsPluginVisited';
import { InstanceContext } from 'api/InstanceProvider';

export const LinuxNode = () => {
  const styles = useStyles2(getStyles);
  const [token, setToken] = useState('');
  const [archType, setArchType] = useState<string>('Amd64');
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 901);
  const isArchTypeSelected = archType === 'Amd64';
  const { instanceData, error } = useContext(InstanceContext);

  useDocsPluginVisited(ComponentKey.TutorialPluginLinuxNodeHasMadeItToBottom, _.get(instanceData, 'orgId', null));

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 901);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const selectArchTypeOptions = [
    { label: 'Amd64', value: 'Amd64' },
    { label: 'Arm64', value: 'Arm64' },
  ];

  const selectHeadings = () => {
    if (isArchTypeSelected) {
      const headings = [
        { value: 'debianAMDPrereq', label: 'Getting Started' },
        { value: 'debianAMDAgent', label: 'AMD64 Agent set up' },
        { value: 'debianAMDVisualize', label: 'Visualize Metrics' },
      ];
      return headings;
    } else {
      const headings = [
        { value: 'debianARMPrereq', label: 'Getting Started' },
        { value: 'debianARMAgent', label: 'ARM64 Agent set up' },
        { value: 'debianAMDVisualize', label: 'Visualize Metrics' },
      ];
      return headings;
    }
  };

  const promId = _.get(instanceData, 'promId', 0);
  const promUrl = _.get(instanceData, 'promUrl', '');
  const lokiId = _.get(instanceData, 'lokiId', 0);
  const lokiUrl = _.get(instanceData, 'lokiUrl', '');

  if (error) {
    return (
      <div>
        <Alert title="Error" severity="error">
          <p>{(error as QueryError)?.data?.message}</p>
        </Alert>
      </div>
    );
  }

  return (
    <div className={styles.canvas}>
      <div className={styles.mainContent}>
        {instanceData === null && (
          <>
            <LoadingBar width={0} />
          </>
        )}
        <TableOfContents headings={selectHeadings()} />
        <div className={styles.container}>
          <>
            {isMobile && <TableOfContentsDropdown headings={selectHeadings()} />}

            <h1>Linux Node Integration</h1>
            {<p></p>}
            <h4>Select Distro:</h4>
            <RadioButtonGroup options={selectArchTypeOptions} value={archType} onChange={(val) => setArchType(val)} />
            {isArchTypeSelected ? (
              <>
                <h2 id="debianAMDPrereq">Debian AMD64</h2>
                <p>
                  This tutorial will show you how to monitor a Debian-AMD64 host, deploy the Grafana Agent to a Ubuntu
                  host, push metrics to Grafana Cloud, and use the preconfigured dashboard to visualize those metrics.
                </p>
                <h3>1. Prerequisites</h3>
                <p>To follow this tutorial, you will need the following:</p>
                <ul id="debianAMDAgent">
                  <li key="debianAmd64Machine">A Debian-AMD64 machine that you want to monitor.</li>
                  <li key="debianAmd64Terminal">Command line (terminal) access to the Linux machine.</li>
                </ul>
                <h4>Generate token</h4>
                <AccessPolicyToken token={token} setToken={setToken} />
                <h3>2. Install the Grafana Agent on the Debian Host</h3>
                <ol>
                  <li key="debianAmd64MachineDebianHost">
                    Open a terminal on the Debian host. You can do this by opening the Terminal application or using an
                    SSH client to connect to the machine remotely.
                  </li>
                  <li key="downloadAgentBinaryDebianAmd">
                    {' '}
                    Download the Grafana Agent Binary by running the following command:
                  </li>
                  <CopyToClipboard
                    snippet={debianAmd(promUrl, promId, lokiUrl, lokiId, token)}
                    height={'125px'}
                    width={'100%'}
                  />
                  <li key="replaceStackInfoDebianAmd">
                    Make sure to replace &lt;prometheus-push-url&gt; with the prometheus push url,
                    &lt;prometheus-instance-id&gt; with the prometheus instance id, &lt;loki-push-url&gt; with the loki
                    push url and &lt;loki-username&gt; with the loki username of your Grafana Cloud stack. This command
                    downloads the Grafana Agent and sets it up on the Debian host.
                  </li>
                  <li key="debianCompleteInstallAmd">
                    Wait for the installation to complete. The Grafana Agent will be installed and automatically
                    configured to send metrics to Grafana Cloud.
                  </li>

                  <li key="amdNodeExporterSetup">
                    <p>
                      Below integrations, insert the following lines and change the URLs according to your environment:
                    </p>
                    <CopyToClipboard snippet={nodeExporterSetup()} height={'500px'} width={'100%'} />

                    <p>
                      Below `logs.configs.scrape_configs`, insert the following lines according to your environment.
                    </p>
                    <CopyToClipboard snippet={logsSetup()} height={'500px'} width={'100%'} />

                    <p> Make sure to replace &lt;your-instance-id&gt; with the name of your instance</p>
                  </li>
                  <li key="debianAmdStartGrafanaAgent">
                    Once the installation is finished, start the Grafana Agent service by running the following command:
                  </li>
                  <CopyToClipboard
                    snippet={'sudo systemctl restart grafana-agent.service'}
                    height={'40px'}
                    width={'100%'}
                  />
                  <p id="debianAMDVisualize">
                    This command restarts the Grafana Agent service, enabling it to collect metrics from the Debian host
                    and send them to Grafana Cloud for visualization and analysis.
                  </p>
                </ol>
                <h3>3. Visualize Metrics in Grafana Cloud</h3>
                <ol>
                  <li key="selectIntegrationDebianAmd">
                    In the left column of the Grafana Cloud interface, click the Integrations and Connections icon. It
                    looks like a lightning bolt.
                  </li>
                  <li key="debianAmdIntegrationsAndConnections">
                    In the Integrations and Connections page, navigate to the Integration - Linux Node.
                  </li>
                  <li key="viewDashboardDebianAmd">
                    Click View Dashboards on the top to view preconfigured dashboards.
                  </li>
                </ol>
                <p>
                  Explore the dashboards to visualize different metrics and gain insights into the performance and
                  health of your Debian host. The dashboards are designed using best practices for observability and
                  provide a comprehensive view of system metrics, resource utilization, network activity, and more.
                </p>

                <p>
                  Congratulations! You have successfully installed the Grafana Agent on your Debian host and visualized
                  the metrics in Grafana Cloud using the Linux Node integration. You can now monitor and analyze the
                  performance of your Debian host in real-time, gaining valuable insights into its health and resource
                  usage.
                </p>
                <br />
                <br />
              </>
            ) : (
              <>
                <h2 id="debianARMPrereq">Debian ARM64</h2>
                <p>
                  This tutorial will show you how to monitor a Debian-arm64 host, deploy the Grafana Agent to a Ubuntu
                  host, push metrics to Grafana Cloud, and use the preconfigured dashboard to visualize those metrics.
                </p>
                <h3>1. Prerequisites</h3>
                <p>To follow this tutorial, you will need the following:</p>
                <ul id="debianARMAgent">
                  <li key="debianArm64Machine">A Debian-ARM64 machine that you want to monitor.</li>
                  <li key="debianArm64Terminal">Command line (terminal) access to the Linux machine.</li>
                </ul>
                {/* <ApiKey pluginId={'cloud-home-app'} onChangeToken={(newToken: any) => setToken(newToken)} /> */}
                <h3>2. Install the Grafana Agent on the Debian Host</h3>
                <ol>
                  <li key="debianArm64MachineDebianHost">
                    Open a terminal on the Debian host. You can do this by opening the Terminal application or using an
                    SSH client to connect to the machine remotely.
                  </li>
                  <li key="downloadAgentBinaryDebianArm">
                    Download the Grafana Agent Binary by running the following command:
                  </li>
                  <CopyToClipboard
                    snippet={debianArm(promUrl, promId, lokiUrl, lokiId, token)}
                    height={'125px'}
                    width={'100%'}
                  />

                  <li key="armNodeExporter">
                    <p>
                      Below integrations, insert the following lines and change the URLs according to your environment:
                    </p>
                    <CopyToClipboard snippet={nodeExporterSetup()} height={'500px'} width={'100%'} />

                    <p>
                      Below `logs.configs.scrape_configs`, insert the following lines according to your environment.
                    </p>
                    <CopyToClipboard snippet={logsSetup()} height={'500px'} width={'100%'} />

                    <p> Make sure to replace &lt;your-instance-id&gt; with the name of your instance</p>
                  </li>

                  <li key="replaceStackInfoDebianArm">
                    Make sure to replace &lt;prometheus-push-url&gt; with the prometheus push url,
                    &lt;prometheus-instance-id&gt; with the prometheus instance id, &lt;loki-push-url&gt; with the loki
                    push url and &lt;loki-username&gt; with the loki username of your Grafana Cloud stack. This command
                    downloads the Grafana Agent and sets it up on the Debian host.
                  </li>
                  <li key="debianCompleteInstallArm">
                    Wait for the installation to complete. The Grafana Agent will be installed and automatically
                    configured to send metrics to Grafana Cloud.
                  </li>
                  <li key="debianArmStartGrafanaAgent">
                    Once the installation is finished, start the Grafana Agent service by running the following command:
                  </li>
                  <CopyToClipboard
                    snippet={'sudo systemctl restart grafana-agent.service'}
                    height={'40px'}
                    width={'100%'}
                  />
                  <p id="debianAMDVisualize">
                    This command restarts the Grafana Agent service, enabling it to collect metrics from the Debian host
                    and send them to Grafana Cloud for visualization and analysis.
                  </p>
                </ol>
                <h3>3. Visualize Metrics in Grafana Cloud</h3>
                <ol>
                  <li key="selectIntegrationDebianArm">
                    In the left column of the Grafana Cloud interface, click the Integrations and Connections icon. It
                    looks like a lightning bolt.
                  </li>
                  <li key="debianArmIntegrationsAndConnections">
                    In the Integrations and Connections page, navigate to the Integration - Linux Node.
                  </li>
                  <li key="viewDashboardDebianArm">
                    Click View Dashboards on the top to view preconfigured dashboards.
                  </li>
                </ol>
                <p>
                  Explore the dashboards to visualize different metrics and gain insights into the performance and
                  health of your Debian host. The dashboards are designed using best practices for observability and
                  provide a comprehensive view of system metrics, resource utilization, network activity, and more.
                </p>
                <p>
                  Congratulations! You have successfully installed the Grafana Agent on your Debian host and visualized
                  the metrics in Grafana Cloud using the Linux Node integration. You can now monitor and analyze the
                  performance of your Debian host in real-time, gaining valuable insights into its health and resource
                  usage.
                </p>
              </>
            )}
          </>
        </div>
      </div>
    </div>
  );
};
