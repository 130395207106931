import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';
import { breakpoints } from 'consts/styles';

export const getStyles = (theme: GrafanaTheme2) => ({
  canvas: css`
    background: ${theme.colors.background.canvas};
    padding: 16px;
  `,
  mainContent: css`
    background: ${theme.colors.background.primary};
    padding-top: 48px;
    border: 1px solid ${theme.colors.border.medium};
  `,

  container: css`
    p, li, h3, h2, h4, h1{
        color: ${theme.colors.text.primary}
    }
      margin-left: auto;
      margin-right: 15%;
      padding: 16px;
      max-width: 800px;
  
      img {
        width: 90%;
        margin-bottom: 16px;
      }
  
      li {
        margin: 16px;
        padding: 4px;
      }

      h1 {
        margin-bottom: 44px;
      }

      h2, h3{
        margin-top: 44px;
        margin-bottom: 16px
      }

      p {
        margin-top: 16px;
        margin-bottom: 16px;
      }

      @media (max-width: ${breakpoints.banner2}px) {
        width: 60%;
      }

      @media (max-width: ${breakpoints.banner1}px) {
        width: 60%;
      }


      @media (max-width: 1601px) {
        width: 60%;
      }
  
      @media (max-width: 4000px) {
        width: 60%;
      }

      @media (max-width: ${breakpoints.menuColumn}px) {
        width: 90%;
        margin-left: 0;
        margin-right: 0;
      }

      @media (min-width: 1399px) {
        margin-left: 358px;
        }
    

      a{
      color: #6e9fff;
      }

      @media (max-width: 901px) {
        width: 90%
        margin-left: auto;
        margin-right: auto;
        h1 {
          font-size: 24px;
        }
      }

      @media (max-width: ${breakpoints.titleText}px) {
        margin-left: auto;
        margin-right: auto;
        h1 {
          font-size: 24px;
          margin: 16px 0;
        }
      }
    `,

  button: css`
    margin-bottom: 36px;
  `,

  backButton: css`
    margin-top: 36px !important;
    margin-left: 24px !important;
  `,
});
