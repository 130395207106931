import React, { createContext, useEffect, useState } from 'react';
import { getBackendSrv } from '@grafana/runtime';

import type { Instance } from 'types/Instance';

export const InstanceContext = createContext(null as any);

interface Props {
  children: React.ReactNode;
}

export const InstanceProvider: React.FC<Props> = ({ children }) => {
  const [error, setError] = useState(null);

  const [instanceData, setInstanceData] = useState(null as Instance | null);

  useEffect(() => {
    const getGrafanaInstanceData = async () => {
      await getBackendSrv()
        .get(`api/plugin-proxy/grafana-selfservetutorials-app/grafanacom-api/instances`)
        .then((res) => {
          setError(null);
          setInstanceData({
            promId: res.hmInstancePromId,
            promUrl: res.hmInstancePromUrl,
            lokiId: res.hlInstanceId,
            lokiUrl: res.hlInstanceUrl,
            id: res.id,
            orgId: res.orgId,
            region: res.regionSlug,
          });
        })
        .catch((e) => setError(e));
    };

    getGrafanaInstanceData();
  }, []);

  const data = { instanceData, error };
  return <InstanceContext.Provider value={data}>{children}</InstanceContext.Provider>;
};
