import React from 'react';
import { InstanceProvider } from 'api/InstanceProvider';
import { CSV } from 'components/BlogPosts/CSV/CSV';
import { JsonApi } from 'components/BlogPosts/JsonApi/JsonApi';
import { KubernetesSelfHostedMetrics } from 'components/BlogPosts/Kubernetes/KubernetesSelfHostedMetrics';
import { LinuxNode } from 'components/BlogPosts/Linux/LinuxNode';
import { MonitorMultipleLinuxHosts } from 'components/BlogPosts/Linux/MonitorMultipleLinuxHosts';
import { MigrateOss } from 'components/BlogPosts/MigrateOSS/MigrateOSS';
import { ServerlessPlatformMonitoring } from 'components/BlogPosts/Serverless/ServerlessPlatformMonitoring';
import { Supabase } from 'components/BlogPosts/Supabase/Supabase';
import { DocsCards } from 'components/DocsCards/DocsCards';
import {  Navigate, Route, Routes } from 'react-router-dom';


export const App = () => {
  return (
    <InstanceProvider>
        <Routes>
            <Route path="/" element={<DocsCards />} />
            <Route path="linux-node" element={<LinuxNode />} />
            <Route path="self-hosted-metrics" element={<KubernetesSelfHostedMetrics />} />
            <Route path="monitor-multiple-linux-hosts" element={<MonitorMultipleLinuxHosts />} />
            <Route path="json-api" element={<JsonApi />} />
            <Route path="serverless-monitoring" element={<ServerlessPlatformMonitoring />} />
            <Route path="csv" element={<CSV />} />
            <Route path="supabase" element={<Supabase />} />
            <Route path="migrate-oss" element={<MigrateOss />} /> 
            <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
    </InstanceProvider>
  );
};
