import { isAdmin } from "utils/validation";


export const remoteWriteYML = (
  promId: number | undefined,
  url: string | undefined,
  token: string | undefined
) => `remote_write:
- url: ${url + '/api/prom/push'}
  basic_auth:
    username: ${promId} 
    password: ${(isAdmin && token) ?? 'your-token'}
`;

export const firstPromInstance = (
  promId: number | undefined,
  url: string | undefined,
  token: string | undefined
) => `global:
  external_labels:
    origin_prometheus: prometheus01
remote_write:
- url: ${url + '/api/prom/push'}
  basic_auth:
    username: ${promId}
    password: ${(isAdmin && token) ?? 'your-token'}
`;

export const secondPromInstance = (
  promId: number | undefined,
  url: string | undefined,
  token: string | undefined
) => `global:
  external_labels:
    origin_prometheus: prometheus02
remote_write:
- url: ${url + '/api/prom/push'}
  basic_auth:
    username: ${promId}
    password: ${(isAdmin && token) ?? 'your-token'}
`;

export const firstHaInstance = (
  promId: number | undefined,
  url: string | undefined,
  token: string | undefined
) => `global:
  external_labels:
    cluster: prom-team1
    __replica__: replica1
remote_write:
- url: ${url + '/api/prom/push'}
  basic_auth:
    username: ${promId}
    password: ${(isAdmin && token) ?? 'your-token'}
`;

export const secondHaInstance = (
  promId: number | undefined,
  url: string | undefined,
  token: string | undefined
) => `global:
  external_labels:
    cluster: prom-team1
    __replica__: replica2
remote_write:
- url: ${url + '/api/prom/push'}
  basic_auth:
    username: ${promId}
    password: ${(isAdmin && token) ?? 'your-token'}
`;
