import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';

export const getStyles = (theme: GrafanaTheme2) => ({
  goBack: css`
    margin-left: 16px;
    position: absolute;
    top: 118px;
    @media (max-width: 901px) {
      display: none;
    }
  `,

  border: css`
    border-right: 1px solid rgba(255, 255, 255, 0.1);
  `,

  headings: css`
    padding-left: 13px;
  `,

  anchors: (isActive: boolean) => css`
    font-weight: ${isActive ? 'bold' : 'normal'};
    color: ${isActive ? theme.colors.text.primary : theme.colors.text.secondary};
  `,

  ulGroup: css`
    position: fixed;
    top: 96px;
    padding: 18px;
    width: 19%;
    z-index: 100;
    margin-left: 8px;
    padding-right: 16px;
    max-width: 350px;
    margin-top: 60px;
    margin-left: 16px;

    li {
      margin-left: 16px;
      margin-right: 48px;
    }

    hr {
      margin-left: 6px;
    }

    @media (max-width: 900px) {
      display: none;
    }
  `,

  listItem: css`
    padding-bottom: 16px;
    overflow-wrap: break-word;
    list-style-type: disc;
  `,

  heading: css`
    width: 1px;
    height: 16px;
    margin: 0;
    display: inline-block;
    background: #4d4d4d;
  `,

  contents: css`
    margin-left: 8px;
    font-size: 14px;
  `,
});
