import React, { useContext, useEffect, useState } from 'react';
import { Alert, LoadingBar, useStyles2 } from '@grafana/ui';
import _ from 'lodash';

import { InstanceContext } from 'api/InstanceProvider';
import { getStyles } from 'components/BlogPosts/Kubernetes/KubernetesSelfHostedMetrics.styles';

import { TableOfContents } from 'components/TableOfContents/TableOfContents';
import { TableOfContentsDropdown } from 'components/TableOfContents/TableOfContentsDropdown';
import { ComponentKey } from 'types/ComponentKey';
import { useDocsPluginVisited } from 'hooks/useDocsPluginVisited';
import { QueryError } from 'types/QueryError';

import { supabaseSteps } from 'components/BlogPosts/Supabase/Supabase.data';

export const Supabase = () => {
    const styles = useStyles2(getStyles);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 901);
    const { instanceData, error } = useContext(InstanceContext);

    useDocsPluginVisited(
        ComponentKey.TutorialPluginSupabaseHasMadeItToBottom,
        _.get(instanceData, 'orgId', null)
    );

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 901);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const headings = [
        { value: 'Step 1', label: 'Gather the Necessary Details from Supabase' },
        { value: 'Step 2', label: 'Set Up Your Metrics Endpoint Integration' },
        { value: 'Step 3', label: 'Install the Supabase Metrics Dashboard on Grafana' },
        { value: 'Step 4', label: 'Conclusion' },
    ];

    if (error) {
        return (
            <div>
                <Alert title="Error" severity="error">
                    {(error as QueryError)?.data?.message}
                </Alert>
            </div>
        );
    }

    return (
        <div className={styles.canvas}>
            <div className={styles.mainContent}>
            {!instanceData && (
      <LoadingBar width={0} />
)}
                <TableOfContents headings={headings} />
                <div className={styles.container}>
                {isMobile && <TableOfContentsDropdown headings={headings} />}

                    {Object.keys(supabaseSteps.steps).map((stepKey) => {
                        const step = supabaseSteps.steps[stepKey];
                        return (
                            <div key={stepKey}>
                                {step.h1 && <h1>{step.h1}</h1>}
                                {step.h1Description && <p dangerouslySetInnerHTML={{ __html: step.h1Description }}></p>}
                                {step.h2 && <h2>{step.h2}</h2>}
                                {step.h2Description && <p dangerouslySetInnerHTML={{ __html: step.h2Description }}></p>}
                                {step.ol && (
                                    <ol>
                                        {step.ol.liElements.map((li: any, index: any) => (
                                            <li key={index} dangerouslySetInnerHTML={{ __html: li }}></li>
                                        ))}
                                    </ol>
                                )}
                                {step.image && (
                                    <div className={styles.imageCard}>
                                        <img src={step.image} alt={`Step ${stepKey}`} />
                                        <p>{step.imgCaption}</p>
                                    </div>
                                )}
                            {step.p && <p>{step.p}</p>}
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};
