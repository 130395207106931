import React, { useEffect, useState } from 'react';
import { Icon, useStyles2 } from '@grafana/ui';
import { appPaths } from 'consts/appPaths';
import { getStyles } from 'components/TableOfContents/TableOfContents.styles';

type Props = {
  headings: Array<{ value: string; label: string }>;
};

export const TableOfContents: React.FC<Props> = ({ headings }) => {
  const styles = useStyles2(getStyles);

  const [activeAnchor, setActiveAnchor] = useState('');

  useEffect(() => {
    const handleScroll = () => {
      const anchors = headings.map((heading) => document.getElementById(heading.value));
      const active = anchors.find((anchor) => anchor && anchor.getBoundingClientRect().top >= 0);
      setActiveAnchor(active?.id || '');
    };

    handleScroll();
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [headings]);

  const handleClick = (id: string) => {
    const anchor = document.getElementById(id);
    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth' });
      setActiveAnchor(id);
    }
  };

  return (
    <>
      <a href={appPaths.basePath} className={styles.goBack}>
        {' '}
        <Icon name="arrow-left" /> Back to tutorials
      </a>
      <ul className={styles.ulGroup}>
        <div className={styles.border}>
          <p className={styles.contents}>Contents</p>
          <div className={styles.headings}>
            {headings.map((heading, index) => (
              <div key={heading.value}>
                {index > 0 && <hr className={styles.heading} />}
                <li key={heading.value} className={styles.listItem}>
                  <a
                    href={`#${heading.value}`}
                    onClick={() => handleClick(heading.value)}
                    className={styles.anchors(heading.value === activeAnchor)}
                  >
                    {heading.label}
                  </a>
                </li>
              </div>
            ))}
          </div>
        </div>
      </ul>
    </>
  );
};
