export const debianAmd = (
  promUrl: string | undefined,
  promId: number | undefined,
  lokiUrl: string | undefined,
  lokiId: number | undefined,
  token: string | undefined
) => {
  return `ARCH="amd64" GCLOUD_HOSTED_METRICS_URL="${
    promUrl + '/api/prom/push'
  }" GCLOUD_HOSTED_METRICS_ID="${promId}" GCLOUD_SCRAPE_INTERVAL="60s" GCLOUD_HOSTED_LOGS_URL="${
    lokiUrl + '/loki/api/v1/push'
  }" GCLOUD_HOSTED_LOGS_ID="${lokiId}" GCLOUD_RW_API_KEY="${token}" /bin/sh -c "$(curl -fsSL https://storage.googleapis.com/cloud-onboarding/agent/scripts/grafanacloud-install.sh)"
    `;
};

export const debianArm = (
  promUrl: string | undefined,
  promId: number | undefined,
  lokiUrl: string | undefined,
  lokiId: number | undefined,
  token: string | undefined
) => {
  return `ARCH="arm64" GCLOUD_HOSTED_METRICS_URL="${
    promUrl + '/api/prom/push'
  }" GCLOUD_HOSTED_METRICS_ID="${promId}" GCLOUD_SCRAPE_INTERVAL="60s" GCLOUD_HOSTED_LOGS_URL="${
    lokiUrl + '/loki/api/v1/push'
  }" GCLOUD_HOSTED_LOGS_ID="${lokiId}" GCLOUD_RW_API_KEY="${token}" /bin/sh -c "$(curl -fsSL https://storage.googleapis.com/cloud-onboarding/agent/scripts/grafanacloud-install.sh)"
    `;
};

export const ansibleConfig = `[defaults]
  inventory = inventory  # Path to the inventory file
  private_key_file = ~/.ssh/id_rsa   # Path to my private SSH Key
  remote_user=root
  `;

export const deployAgentYML = (
  promId: number | undefined,
  lokiId: number | undefined,
  promUrl: string | undefined,
  lokiUrl: string | undefined,
  token: string | undefined
) => `name: Install Grafana Agent
  hosts: all
  become: true
  
  vars:
    grafana_cloud_api_key: ${token ?? 'your-api-key'}        
    metrics_username: ${promId}                 
    logs_username: ${lokiId}                           
    prometheus_url: ${promUrl + '/api/prom/push'}                    
    loki_url: ${lokiUrl + '/loki/api/v1/push'}                                
  tasks: 
    - name: Install Grafana Agent
      ansible.builtin.include_role:
        name: grafana_agent
      vars:
        grafana_agent_metrics_config:
          configs:
            - name: integrations
              remote_write:
                - basic_auth:
                    password: "{{ grafana_cloud_api_key }}"
                    username: "{{ metrics_username }}"
                  url: "{{ prometheus_url }}"
  
          global:
            scrape_interval: 60s
          wal_directory: /tmp/grafana-agent-wal
  
        grafana_agent_logs_config:
          configs:
            - name: default
              clients:
                - basic_auth:
                    password: "{{ grafana_cloud_api_key }}"
                    username: "{{ logs_username }}"
                  url:  "{{ loki_url }}"
              positions:
                filename: /tmp/positions.yaml
              target_config:
                sync_period: 10s
              scrape_configs:
                - job_name: varlogs
                  static_configs:
                    - targets: [localhost]
                      labels:
                        instance: $/{HOSTNAME:-default}
                        job: varlogs
                        __path__: /var/log/*log
        grafana_agent_integrations_config:
          node_exporter:
            enabled: true
            instance: $/{HOSTNAME:-default}
          prometheus_remote_write:
            - basic_auth:
                password: "{{ grafana_cloud_api_key }}"
                username: "{{ metrics_username }}"
              url: "{{ prometheus_url }}"
  `;

export const inventoryFile = `146.190.208.216    # hostname = ubuntu-01
  146.190.208.190    # hostname = ubuntu-02
  137.184.155.128    # hostname = centos-01
  146.190.216.129    # hostname = centos-02
  198.199.82.174     # hostname = debian-01
  198.199.77.93      # hostname = debian-02
  143.198.182.156    # hostname = fedora-01
  143.244.174.246    # hostname = fedora-02
  `;
/* cSpell:disable */
export const nodeExporterSetup = () => {
  return `node_exporter:
      enabled: true
      # disable unused collectors
      disable_collectors:
        - ipvs #high cardinality on kubelet
        - btrfs
        - infiniband
        - xfs
        - zfs
      # exclude dynamic interfaces
      netclass_ignored_devices: "^(veth.*|cali.*|[a-f0-9]{15})$"
      netdev_device_exclude: "^(veth.*|cali.*|[a-f0-9]{15})$"
      # disable tmpfs
      filesystem_fs_types_exclude: "^(autofs|binfmt_misc|bpf|cgroup2?|configfs|debugfs|devpts|devtmpfs|tmpfs|fusectl|hugetlbfs|iso9660|mqueue|nsfs|overlay|proc|procfs|pstore|rpc_pipefs|securityfs|selinuxfs|squashfs|sysfs|tracefs)$"
      # drop extensive scrape statistics
      metric_relabel_configs:
      - action: drop
        regex: node_scrape_collector_.+
        source_labels: [__name__]
      relabel_configs:
      - replacement: <your-instance-name>
        target_label: instance
  `;
};

/* cSpell:disable */
export const logsSetup = () => {
  return `
  - job_name: integrations/node_exporter_journal_scrape
    journal:
      max_age: 24h
      labels:
        instance: <your-instance-name>
        job: integrations/node_exporter
    relabel_configs:
    - source_labels: ['__journal__systemd_unit']
      target_label: 'unit'
    - source_labels: ['__journal__boot_id']
      target_label: 'boot_id'
    - source_labels: ['__journal__transport']
      target_label: 'transport'
    - source_labels: ['__journal_priority_keyword']
      target_label: 'level'
  - job_name: integrations/node_exporter_direct_scrape
    static_configs:
    - targets:
      - localhost
      labels:
        instance: <your-instance-name>
        __path__: /var/log/{syslog,messages,*.log}
        job: integrations/node_exporter
          `;
};
