interface Step {
  h1?: string;
  h1Description?: string;
  h2?: string;
  h2Description?: string;
  ol?: {
    liElements: string[];
  };
  p?: string;
  image?: string;
  imgCaption?: string;
}

interface SupabaseSteps {
  steps: {
    [step: string]: Step;
  };
}

import HostedMetrics3 from 'components/BlogPosts/Supabase/supabaseimg/hosted-metrics-3.png';
import HostedMetrics4 from 'components/BlogPosts/Supabase/supabaseimg/hosted-metrics-4.png';
import SupabasePromGrafana from 'components/BlogPosts/Supabase/supabaseimg/supabase-grafana-prometheus.png';

export const supabaseSteps: SupabaseSteps = {
  steps: {
    step1: {
      h1: "Collecting Supabase Metrics Using a Hosted Collector",
      h1Description:
        "This tutorial will guide you on collecting Supabase metrics with a hosted collector. We'll guide you through each step to ensure you can monitor your application's performance seamlessly. By the end, you'll have Supabase metrics neatly displayed on a dashboard.",
      h2: "Step 1: Gather the Necessary Details from Supabase",
      h2Description:
        "First, you'll need to log into your Supabase account. If you don't have one, you can create it at <a href='https://supabase.com/'>Supabase's official website.</a>",
      ol: {
        liElements: [
          'Once logged in, navigate to the **API** section on the dashboard to access your project settings.',
          `Here, you need to locate two crucial pieces of information:<br/> - **Project ID** <br /> - **Service Role Key**`,
        ],
      },
      p: 'These credentials are essential as they will be used in your metrics collection URL and for authentication.',
      image: HostedMetrics3,
      imgCaption: 'Supabase admin panel'
    },
    step2: {
      h2: "Step 2: Set Up Your Metrics Endpoint Integration",
      h2Description: "With your Project ID and Service Role Key at hand, it's time to head over to the connections console to set up the integration.",
      ol: {
        liElements: [
          'Utilize the left-side navigation pane and proceed to **Home <Connections> Connect Data**.',
          'Look for the **Metrics Endpoint integration** option and select it to configure your metrics collection.',
          'You will need to enter a **Scrape URL**. For Supabase, the format is as follows: `https://<project-ref>.supabase.co/customer/v1/privileged/metrics`. Make sure to replace `<project-ref>` with your actual Project ID.',
          'Select **Basic Auth** and enter your username as `service_role`, and the password is the Service Role Key you noted earlier.',
          'To ensure everything is perfectly set, use the **Test Connection** button. If all goes well, you should get a green alert indicating your setup is correct.',
          'Hit **Save** to finalize the scrape job.',
        ],
      },
      image: HostedMetrics4,
      imgCaption: 'Metrics Endpoint screen',
      
    },
    step3: {
      h2: "Step 3: Install the Supabase Metrics Dashboard on Grafana",
      h2Description: "Now that your data collection is active, it's time to visualize it on a dashboard.",
      ol: {
        liElements: [
          'Download the prebuilt dashboard JSON file from <a href="https://github.com/supabase/supabase-grafana/blob/main/grafana/dashboard.json">this GitHub repository</a>.',
          "Import the JSON file to your Grafana instance by going to Dashboard > Import Dashboard > via JSON, and point to your new Supabase DS",
          "Once, you import the JSON, You will get a dashboard that looks like:",
        ],
      },
      image: SupabasePromGrafana,
      imgCaption: 'The end product!'
    },
    step4: {
      h2: "Conclusion",
      p: "Congratulations! You've successfully set up metric collection for Supabase with a Hosted Collector. This integration enhances your monitoring capabilities, providing valuable insights into your application's performance.",
    },
  },
};
