import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';
import { breakpoints } from 'consts/styles';

export const getStyles = (theme: GrafanaTheme2) => ({
  canvas: css`
    background: ${theme.colors.background.canvas};
    padding: 16px;
  `,

  mainContent: css`
    background: ${theme.colors.background.primary};
    padding-top: 60px;
    border: 1px solid ${theme.colors.border.medium};
  `,
  container: css`
    p,
    li,
    h3,
    h2,
    pre,
    h4,
    h1 {
      color: ${theme.colors.text.primary};
    }

    margin-left: 28%;
    margin-right: 15%;
    padding: 16px;
    max-width: 1200px;

    img {
      width: 90%;
      margin-bottom: 16px;
      margin-top: 16px;
    }

    li {
      margin: 16px;
      padding: 4px;
    }

    h1 {
      margin-bottom: 44px;
    }

    h2,
    h3 {
      margin-top: 44px;
      margin-bottom: 16px;
    }

    p {
      margin-top: 16px;
      margin-bottom: 16px;
    }

    pre {
      max-width: 599px;
    }

    table {
      width: 100%;
      border-collapse: collapse;
    }

    th, td {
      border: 1px solid #ddd;
      padding: 8px;
      text-align: left;
    }
    th {
      background-color: #222222;
    }

    @media (max-width: ${breakpoints.banner2}px) {
      width: 60%;
    }
    @media (max-width: ${breakpoints.banner1}px) {
      width: 60%;
    }

    @media (max-width: 1601px) {
      width: 60%;
    }

    @media (max-width: 4000px) {
      width: 60%;
    }

    @media (max-width: ${breakpoints.menuColumn}px) {
      width: 90%;
      margin-left: 0;
      margin-right: 0;
    }

    @media (max-width: ${breakpoints.titleText}px) {
      margin-left: auto;
      margin-right: auto;
      h1 {
        font-size: 24px;
        margin: 16px 0;
      }
    }

    @media (max-width: 901px) {
      margin-left: auto;
      margin-right: auto;
      h1 {
        font-size: 24px;
        margin: 16px 0;
      }
    }

    @media (min-width: 1399px) {
      margin-left: 358px;
    }

    a {
      color: #6e9fff;
    }
  `,

  button: css`
    margin-bottom: 36px;
  `,
  imageCard: css`
  max-width: 90%;
  background-color: ${theme.colors.secondary.main};
  border: 2px solid ${theme.colors.secondary.border};
  padding: 8px;
  border-radius: 7px;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 1); /* Subtle box shadow */
  margin-top: 16px;
  margin-bottom: 16px;

  img {
    width: 100% !important;
  }

  p {
    text-align: center;
  }
`,

});
