import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';

export const getStyles = (theme: GrafanaTheme2) => ({
  container: css`
    width: 100%;
  `,
  title: css`
    font-size: ${theme.typography.size.base};
    color: ${theme.colors.text};
    font-weight: ${theme.typography.fontWeightLight};
    margin-bottom: ${theme.spacing(1)};
  `,
  description: css`
    font-size: ${theme.typography.size.sm};
    color: ${theme.colors.text};
    line-height: ${theme.spacing(2)};
    color: ${theme.colors.text};
    margin-bottom: ${theme.spacing(1)};
  `,
  codeWrapper: css`
    max-width: 599px;
    position: relative;
    margin-bottom: ${theme.spacing(2)};

    textarea::-webkit-resizer {
      background-color: ${theme.components.input.background};
    }

    button {
      background: rgba(112, 128, 144, 0.06);
      position: absolute;
      right: 0;
      padding-left: 8px;
      padding-right: 1px;
      margin: 4px;

      &[aria-label='Copied'] {
        background-color: transparent;
        color: ${theme.colors.success.text};
      }
    }
  `,
  clipboard: css`
    font-size: ${theme.typography.bodySmall.fontSize};
    color: ${theme.colors.text};
    line-height: ${theme.spacing(2)};
    padding: 0.5rem 0.75rem 0.5rem 0.5rem;
    white-space: pre-wrap;
    word-wrap: break-word;
    background: ${theme.colors.background.canvas} !important;
    border-radius: ${theme.spacing(0.5)};
    border: 1px solid ${theme.colors.border.strong};
    overflow: auto;
    min-width: 200px;
    max-width: 700px;
    min-height: 42px;
    max-height: 300px;
    resize: both;
    font-family: ${theme.typography.fontFamilyMonospace};
    margin-bottom: ${theme.spacing(1)};
  `,
});
