export enum ComponentKey {
  OssMigrationGuideTutorial ='oss-migration-guide-tutorial',
  SupabaseTutorial = 'supabase-tutorial',
  LinuxTutorial = 'linux-tutorial',
  SelfHostedMetricsTutorial = 'selfhosted-metrics-tutorial',
  MultipleLinuxHostsTutorial = 'multiple-linux-hosts-tutorial',
  JsonApiTutorial ='json-api-tutorial',
  CSVTutorial = 'csv-tutorial',
  ServerlessSetupTutorial = 'serverless-setup-tutorial',

  TutorialPluginKubernetesSelfHostedMetricsHasMadeItToBottom = 'tutorial-plugin-kubernetes-self-hosted-metrics-has-made-it-to-bottom',
  TutorialPluginLinuxNodeHasMadeItToBottom = 'tutorial-plugin-linux-node-has-made-it-to-bottom',
  TutorialPluginMonitorMultipleLinuxHostsHasMadeItToBottom = 'tutorial-plugin-monitor-multiple-linux-hosts-has-made-it-to-bottom',
  TutorialPluginEnteredFromDemoDashboard = 'tutorial-plugin-entered-from-demo-dashboard',
  TutorialPluginEnteredFromOnboardingFlow = 'tutorial-plugin-entered-from-onboarding-flow',
  TutorialPluginJSONAPIHasMadeItToBottom = 'tutorial-plugin-json-api-has-made-it-to-bottom',
  TutorialPluginCSVHasMadeItToBottom = 'tutorial-plugin-csv-has-made-it-to-bottom',
  TutorialPluginAwsServerlessHasMadeItToBottom = 'tutorial-plugin-aws-serverless-has-made-it-to-bottom',
  TutorialPluginSupabaseHasMadeItToBottom = 'tutorial-plugin-supabase-has-made-it-to-bottom',
}
