import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';

export const getStyles = (theme: GrafanaTheme2) => ({
  container: css`
    left: 0;
    width: 100%;
    z-index: 1;
    background-color: ${theme.colors.background.primary};
    display: flex;
    align-items: center;
    justify-content: space-between;

    a {
      color: ${theme.colors.text.primary};
      margin-left: 8px;
    }

    margin-bottom: 32px;
  `,

  dropdown: css`
    width: 95%;
    max-width: 600px;
    padding: 8px;
  `,
  navigationContents: css`
    p {
      margin: 0;
      margin-bottom: 8px;
      margin-top: 16px;
    }

    a {
      color: ${theme.colors.text.primary};
      font-size: 16px;
    }
  `,
});
