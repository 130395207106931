import { Alert, useStyles2 } from '@grafana/ui';
import React, { useContext, useEffect, useState } from 'react';
import step1 from 'components/BlogPosts/JsonApi/jsonApiImg/step1.png';
import step2 from 'components/BlogPosts/JsonApi/jsonApiImg/step2.png';
import step3 from 'components/BlogPosts/JsonApi/jsonApiImg/step3.png';
import step4a from 'components/BlogPosts/JsonApi/jsonApiImg/step4a.png';
import step4b from 'components/BlogPosts/JsonApi/jsonApiImg/step4b.png';
import step5a from 'components/BlogPosts/JsonApi/jsonApiImg/step5a.png';
import step5b1 from 'components/BlogPosts/JsonApi/jsonApiImg/step5b1.png';
import step5b3 from 'components/BlogPosts/JsonApi/jsonApiImg/step5b3.png';
import step6 from 'components/BlogPosts/JsonApi/jsonApiImg/step6.png';
import { TableOfContents } from 'components/TableOfContents/TableOfContents';
import { TableOfContentsDropdown } from 'components/TableOfContents/TableOfContentsDropdown';
import { ComponentKey } from 'types/ComponentKey';
import { useDocsPluginVisited } from 'hooks/useDocsPluginVisited';
import _ from 'lodash';
import { InstanceContext } from 'api/InstanceProvider';
import { QueryError } from '@grafana-cloud/grafana-com-api';
import { getStyles } from 'components/BlogPosts/JsonApi/JsonApi.styles';

export const JsonApi = () => {
  const styles = useStyles2(getStyles);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 901);
  const { instanceData, error } = useContext(InstanceContext);

  useDocsPluginVisited(ComponentKey.TutorialPluginJSONAPIHasMadeItToBottom, _.get(instanceData, 'orgId', null));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 901);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const headings = [
    { value: 'download', label: 'Download JSON API' },
    { value: 'buildDataSource', label: 'Building A Data Source' },
    { value: 'addVisualization', label: 'Adding A Visualization' },
    { value: 'addQuery', label: 'Adding A Query' },
    { value: 'dashboardScreen', label: 'Adding A Dashboard' },
  ];

  if (error) {
    return (
      <div>
        <Alert title="Error" severity="error">
          <p>{(error as QueryError)?.data?.message}</p>
        </Alert>
      </div>
    );
  }

  return (
    <div className={styles.canvas}>
      <div className={styles.mainContent}>
        <TableOfContents headings={headings} />
        <div className={styles.container}>
          <>
            {isMobile && <TableOfContentsDropdown headings={headings} />}
            <div className={styles.headerSection}>
              <h1>JSON API Quick Start</h1>
              <p>
                This quick start will show you how to create a dashboard using JSON Api and a publicly available Star
                Wars API
              </p>
            </div>

            <ol>
              <li key="navigateToConnectionsPanel">
                <p>
                  Log into your Grafana Cloud stack and navigate to the Connections panel. Search for “JSON API” and
                  select the card.
                </p>
                <div className={styles.imageCard}>
                  <img src={step1} alt="connections panel" />
                  <hr />
                  <p key="jsonApiInstalled" id="download">
                    Grafana - Add new connection (Screenshot)
                  </p>
                </div>
              </li>
              <h2>Download and install JSON API</h2>
              <li>
                <p>Once you’ve installed the JSON API data source, click “Create a JSON API data source.”</p>
                <div className={styles.imageCard}>
                  <img src={step2} alt="install plugin screen" />
                  <hr />
                  <p id="buildDataSource">Grafana - JSON API install screen (Screenshot)</p>
                </div>
              </li>
              <h2>Building a data source</h2>
              <li key="buildingDataSource">
                <p>
                  In the configuration page, enter a URL you would like to query. The URL should be a public api that
                  requires no authentication. Click Save & test . If successful, you’ll see a success message, with a
                  link to be able to visualize this data source by building a dashboard. Click “Building a dashboard” to
                  continue.
                </p>
                <a href="https://swapi.dev/api/planets/?format=json" target="_blank" rel="noreferrer">
                  Data set link for tutorial&#8599;
                </a>
                <div className={styles.imageCard}>
                  <img src={step3} alt="configure data source" />
                  <hr />
                  <p id="addVisualization">Grafana - Configure JSON API url screen (Screenshot)</p>
                </div>
              </li>
              <h2>Adding a visualization</h2>
              <li key="addVisualization">
                <p>
                  In the dashboard creation page, select “Add visualization” and select your “Star Wars” data source
                </p>
                <div className={styles.imageCard}>
                  <img src={step4a} alt="add visualization screen" />
                  <hr />
                  <p>Grafana - Add visualization for dashboard (Screenshot)</p>
                </div>
                <div className={styles.imageCard}>
                  <img src={step4b} alt="select data source screen" />
                  <hr />
                  <p id="addQuery">Grafana - Select datasource (Screenshot)</p>
                </div>
              </li>
              <h2>Adding a query</h2>
              <li key="addQuery">
                <p>
                  Once selected, we can add a query. Query the API’s name using the
                  <a href="https://grafana.github.io/grafana-json-datasource/jsonpath/"> JSONPath query language.</a> If
                  we use <pre>$..name</pre>
                </p>
                <p>This query will return the names of the planets in a table visualization.</p>
                <div className={styles.imageCard}>
                  <img src={step5a} alt="planet name" />
                  <hr />
                  <p>Grafana - Add planet field in query editor (Screenshot)</p>
                </div>
                <br />
                <p>
                  To add a second query to work with more quantitative data, lets look at the `rotation_period` To view
                  the rotation period we can click the plus icon to the right of the Alias field and type in{' '}
                  <pre>$..rotation_period</pre> At the top right of your screen, you can see the table visualization, if
                  you click the drop down and select bar chart, you can see the rotation period as a chart and the name
                  of the planet in the labels in the chart
                </p>
                <div className={styles.imageCard}>
                  <img src={step5b1} alt="rotation period" />
                  <hr />
                  <p>Grafana - Add rotation period field in query editor (Screenshot)</p>
                </div>

                <div className={styles.imageCard}>
                  <img src={step5b3} alt="select save" />
                  <hr />
                  <p id="dashboardScreen">Grafana - Click save (Screenshot)</p>
                </div>
              </li>
              <h2>Dashboard screen</h2>
              <li key="dashboardScreen">
                <p>Once saved, you’ll be redirected to the dashboard screen.</p>
                <div className={styles.imageCard}>
                  <img src={step6} alt="dashboard screen" />
                  <hr />
                  <p>Grafana - Saved panel in dash (Screenshot)</p>
                </div>
              </li>
            </ol>
          </>
        </div>
      </div>
    </div>
  );
};
