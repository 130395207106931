import { useEffect } from 'react';

import { ActionType, trackAction } from 'utils/tracking';
import { FlowEventName } from 'types/FlowEventName';

export const useDocsPluginVisited = (componentKey: string, orgId: number) => {
  useEffect(() => {
    const handleScroll = () => {
      const bottom = window.scrollY + window.innerHeight === window.document.body.scrollHeight;
      if (bottom) {
        trackAction(FlowEventName.DOCS_PLUGIN_EVENT, componentKey, ActionType.visit, orgId);
      }
    };

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [componentKey, orgId]);
};
