import React, { useContext, useEffect, useState } from 'react';
import { Alert, LoadingBar, useStyles2 } from '@grafana/ui';
import _ from 'lodash';

import { InstanceContext } from 'api/InstanceProvider';
import { getStyles } from 'components/BlogPosts/Kubernetes/KubernetesSelfHostedMetrics.styles';
import {
  firstHaInstance,
  firstPromInstance,
  remoteWriteYML,
  secondHaInstance,
  secondPromInstance,
} from 'components/BlogPosts/Kubernetes/preTagSnippets';
import { TableOfContents } from 'components/TableOfContents/TableOfContents';
import { CopyToClipboard } from 'components/CopyToClipboard/CopyToClipboard';
import { TableOfContentsDropdown } from 'components/TableOfContents/TableOfContentsDropdown';
import { AccessPolicyToken } from 'components/AccessPolicyToken/AccessPolicyToken';
import { ComponentKey } from 'types/ComponentKey';
import { useDocsPluginVisited } from 'hooks/useDocsPluginVisited';
import { QueryError } from 'types/QueryError';
import PromChart from 'components/BlogPosts/Kubernetes/KubernetesImg/localprom.jpg'

export const KubernetesSelfHostedMetrics = () => {
  const styles = useStyles2(getStyles);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 901);
  const [token, setToken] = useState('');
  const { instanceData, error } = useContext(InstanceContext);

  useDocsPluginVisited(
    ComponentKey.TutorialPluginKubernetesSelfHostedMetricsHasMadeItToBottom,
    _.get(instanceData, 'orgId', null)
  );

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 901);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const headings = [
    { value: 'selfHost', label: 'Send metrics to Grafana Cloud from self hosted Prometheus in Kubernetes' },
    { value: 'singlePromInstance', label: 'Sending metrics from a single prometheus instance' },
    { value: 'multiPromInstance', label: 'Sending metrics from multiple prometheus instances' },
    { value: 'highAvail', label: 'Sending metrics from multiple high-availability Prometheus instances' },
  ];

  const promId = _.get(instanceData, 'promId', null);
  const promUrl = _.get(instanceData, 'promUrl', '');

  if (error) {
    return (
      <div>
        <Alert title="Error" severity="error">
          {(error as QueryError)?.data?.message}
        </Alert>
      </div>
    );
  }

  return (
    <div className={styles.canvas}>
      <div className={styles.mainContent}>
        {instanceData === null && (
          <>
            <LoadingBar width={0} />
          </>
        )}
        <TableOfContents headings={headings} />
        <div className={styles.container}>
          <>
            {isMobile && <TableOfContentsDropdown headings={headings} />}
            <h1 id="selfHost">
              Send metrics, logs and traces to Grafana Cloud from self-hosted Prometheus, Loki and Tempo
            </h1>
            <hr />
            <p id="singlePromInstance">
              To get Prometheus metrics into Grafana Cloud, configure Prometheus to push scraped samples using
              remote_write. remote_write allows you to forward scraped samples to compatible remote storage endpoints as
              shown in the following diagram.
            </p>
            <div className={styles.imageCard}>

            <img
              src={PromChart}
              alt="Self Hosted Metrics diagram"
            />
            <p>Self Hosted Metrics Diagram</p>
            </div>
            <h2>Sending metrics from a single prometheus instance</h2>
            <hr />
            <h4>Generate token</h4>
            <AccessPolicyToken token={token} setToken={setToken} />

            <p>
              Add the following remote_write snippet to your Prometheus configuration file (often prometheus.yml). You
              can find the /api/prom/push URL, username, and password for your metrics endpoint by clicking on Details
              in the Prometheus card of the Cloud Portal:
            </p>

            {promId && promUrl !== undefined ? (
              <CopyToClipboard snippet={remoteWriteYML(promId, promUrl, token)} height={'135px'} width={'100%'} />
            ) : (
              <></>
            )}
            <p id="multiPromInstance">
              If you are using the Prometheus Helm chart, You can add the above configuration under the remote_write
              variable in{' '}
              <a
                href="https://github.com/prometheus-community/helm-charts/blob/main/charts/prometheus/values.yaml"
                target="_blank"
                rel="noreferrer"
              >
                Values.yml{' '}
              </a>
              and run:
            </p>
            <pre>helm update [RELEASE_NAME] -f values.yml</pre>
            <h2>Sending metrics from multiple prometheus instances</h2>
            <hr />
            <p>
              When sending metrics from multiple Prometheus instances, you can use the external_labels parameter to
              label time series data with an instance identifier. Append any external_labels to the global section of
              your Prometheus configuration file. You can find the /api/prom/push URL, username, and password for your
              metrics endpoint by clicking on Details in the Prometheus card of the Cloud Portal:
            </p>
            <p>First Prometheus instance:</p>
            {promId && promUrl !== undefined ? (
              <CopyToClipboard snippet={firstPromInstance(promId, promUrl, token)} height={'200px'} width={'100%'} />
            ) : (
              <></>
            )}
            <p>Second Prometheus Instance:</p>
            {promId && promUrl !== undefined ? (
              <CopyToClipboard snippet={secondPromInstance(promId, promUrl, token)} height={'200px'} width={'100%'} />
            ) : (
              <></>
            )}
            <p id="highAvail">
              If you are using the Prometheus Helm chart, You can add the above configuration under the global and
              remote_write variable in {' '}
              <a
                href="https://github.com/prometheus-community/helm-charts/blob/main/charts/prometheus/values.yaml"
                target="_blank"
                rel="noreferrer"
              >
                Values.yml
              </a>
              {' '}
              and run:
            </p>
            <pre>helm update [RELEASE_NAME] -f values.yml</pre>
            <h2> Sending metrics from multiple high-availability Prometheus instances</h2>
            <hr />
            <p>
              If you’re shipping data to Grafana Cloud Metrics from multiple Prometheus instances in a high-availability
              (HA) configuration, you should use the cluster and replica labels to deduplicate data received at the
              Grafana Cloud Metrics endpoint. Add these to the external_labels section in your Prometheus configuration
              file. You can find the /api/prom/push URL, username, and password for your metrics endpoint by clicking on
              Details in the Prometheus card of the Cloud Portal:
            </p>
            <p>First HA Prometheus instance:</p>
            {promId && promUrl !== undefined ? (
              <CopyToClipboard snippet={firstHaInstance(promId, promUrl, token)} height={'200px'} width={'100%'} />
            ) : (
              <></>
            )}
            <p>Second HA Prometheus instance:</p>
            {promId && promUrl !== undefined ? (
              <CopyToClipboard snippet={secondHaInstance(promId, promUrl, token)} height={'200px'} width={'100%'} />
            ) : (
              <></>
            )}
            <p>
              If you are using the Prometheus Helm chart, You can add the above configuration under the global and
              remote_write variable in {' '}
              <a
                href="https://github.com/prometheus-community/helm-charts/blob/main/charts/prometheus/values.yaml"
                target="_blank"
                rel="noreferrer"
              >
                Values.yml
              </a>
              {' '}
              and run:
            </p>
            <pre>helm update [RELEASE_NAME] -f values.yml</pre>
            <p>
              The cluster label will identify the HA Prometheus cluster shipping data to Grafana Cloud Metrics. The
              replica label should be set to a unique value within the HA Prometheus cluster. It will be dropped when
              Grafana Cloud Metrics deduplicates and ingests the data.
            </p>
          </>
        </div>
      </div>
    </div>
  );
};
