// Breakpoints
export const breakpoints = {
  menuColumn: 650,
  titleText: 768,
  banner2: 896,
  menuRows: 500,
  banner1: 964,
  addDataButton: 860,
  wideScreen: 1600,
  panelsContainer: 1100,
};
