import React, { useState } from 'react';
import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';
import { Card, ClipboardButton, Field, Icon, RadioButtonGroup, useStyles2 } from '@grafana/ui';

import { TokenType } from 'components/AccessPolicyToken/token.enum';
import { isAdmin } from 'utils/validation';
import { CreateAccessPolicyToken } from 'components/AccessPolicyToken/CreateAccessPolicyToken';

interface AccessPolicyTokenProps {
  token: string;
  setToken: (token: string) => void;
}

export const AccessPolicyToken: React.FC<AccessPolicyTokenProps> = ({ setToken, token }) => {
  const getTokenStyles = (theme: GrafanaTheme2) => ({
    formGroup: css`
      margin-bottom: 20px;
    `,
    card: css`
      background-color: rgb(55,45,27);
    `,
    cardsHeader: css`
      margin-top: 0px !important;
    `,
    inputContainer: css`
    display: flex;
    align-items: center;
    `,

    inputGroup: css`
    margin-right: 8px;
    box-sizing: border-box; 

    input {
      width: 550px;
      padding: 4px 30px 4px 4px; 
        border: 0.10px solid gray;
        box-sizing: border-box; 
    }
    `,
    
    clipboardButton: css`
    flex-shrink: 0; 
    `
  });

  const tokenOptions = [
    { label: 'Create a new token', value: TokenType.NewKey },
    { label: 'Use an existing token', value: TokenType.ExistingKey },
  ];

  const styles = useStyles2(getTokenStyles);
  const [tokenType, setTokenType] = useState<TokenType>(TokenType.NewKey);

  if (!isAdmin) {
    return (

      <Card className={styles.card}>
        <Card.Heading className={styles.cardsHeader}>
          Permission denied
        </Card.Heading>
        <Card.Figure>
          <Icon name="exclamation-triangle" color="orange" />
        </Card.Figure>
        <Card.Description>
          Only admins have permissions to manage access policy tokens.
          Please login to an organization where you are an admin,
          or ask the admin to update your role.
        </Card.Description>
      </Card>
    )
  }
  return (
    <>
      <p>Use a Grafana.com access policy token</p>
      <div className={styles.formGroup}>
        <RadioButtonGroup value={tokenType} options={tokenOptions} onChange={(value) => setTokenType(value)} />
      </div>

      {tokenType === TokenType.ExistingKey ? (
        <div className={styles.inputContainer}>
          <Field label="Access Policy Token" required>
          <div className={styles.inputGroup}>
            <input
              id="token-input"
              placeholder="Enter existing access policy token"
              required
              onChange={(e) => {
                const token = e.currentTarget.value;
                setToken(token);
              }}
            />
            </div>
          </Field>
          <ClipboardButton
            icon="copy"
            variant="primary"
            getText={() => token}
            className={styles.clipboardButton}
          />
        </div>

      ) : (
        <CreateAccessPolicyToken setToken={setToken} />
      )}
    </>
  );
};
