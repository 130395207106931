import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { ClipboardButton, useStyles2 } from '@grafana/ui';
import { getStyles } from 'components/CopyToClipboard/CopyToClipboard.styles';

interface ClipboardProps {
  title?: string;
  description?: string;
  snippet?: string;
  height?: string;
  width?: string;
}

export const CopyToClipboard: React.FC<ClipboardProps> = ({ title, description, snippet, height, width }) => {
  const styles = useStyles2(getStyles);
  const [text, setText] = useState(snippet || '');
  const [containerWidth, setContainerWidth] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useLayoutEffect(() => {
    if (containerRef?.current) {
      setContainerWidth(containerRef?.current.offsetWidth);
    }
  }, []);

  useEffect(() => {
    setText(snippet || '');
  }, [snippet]);

  return (
    <div className={styles.container} ref={containerRef}>
      {title && <p className={styles.title}>{title}</p>}
      {description && <p className={styles.description}>{description}</p>}
      <div className={styles.codeWrapper}>
        <ClipboardButton icon="copy" getText={() => text} data-testid="clipboard-button" />
        <textarea
          data-testid="copy-to-clipboard"
          value={text}
          ref={textareaRef}
          readOnly
          onChange={(event) => setText(event.target.value)}
          style={{
            height: height || 'auto',
            width: width || containerWidth,
          }}
          className={styles.clipboard}
        />
      </div>
    </div>
  );
};
