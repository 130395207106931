import { Alert, useStyles2 } from '@grafana/ui';
import React, { useContext, useEffect, useState } from 'react';
import configure from 'components/BlogPosts/CSV/csvImg/configure.png';
import dashboard from 'components/BlogPosts/CSV/csvImg/dashboard.png';
import newPanel from 'components/BlogPosts/CSV/csvImg/newpanel.png';
import panelSelect from 'components/BlogPosts/CSV/csvImg/panelselect.png';
import panelInfo from 'components/BlogPosts/CSV/csvImg/panelinfo.png';
import csvSearch from 'components/BlogPosts/CSV/csvImg/csvSearch.png';
import addNewConnection from 'components/BlogPosts/CSV/csvImg/addNewConnection.png';
import installCsv from 'components/BlogPosts/CSV/csvImg/installCsv.png';
import { TableOfContents } from 'components/TableOfContents/TableOfContents';
import { TableOfContentsDropdown } from 'components/TableOfContents/TableOfContentsDropdown';
import { ComponentKey } from 'types/ComponentKey';
import { useDocsPluginVisited } from 'hooks/useDocsPluginVisited';
import _ from 'lodash';
import { QueryError } from '@grafana-cloud/grafana-com-api';
import { InstanceContext } from 'api/InstanceProvider';
import { getStyles } from 'components/BlogPosts/CSV/CSV.styles';

export const CSV = () => {
  const styles = useStyles2(getStyles);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 901);
  const { instanceData, error } = useContext(InstanceContext);

  useDocsPluginVisited(ComponentKey.TutorialPluginCSVHasMadeItToBottom, _.get(instanceData, 'orgId', null));

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 901);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const headings = [
    { value: 'setup', label: 'Setup' },
    { value: 'gettingStarted', label: 'Getting Started' },
    { value: 'buildingDashboard', label: 'Building A Dashboard' },
  ];

  if (error) {
    return (
      <div>
        <Alert title="Error" severity="error">
          <p>{(error as QueryError)?.data?.message}</p>
        </Alert>
      </div>
    );
  }

  return (
    <div className={styles.canvas}>
      <div className={styles.mainContent}>
        <TableOfContents headings={headings} />
        <div className={styles.container}>
          <>
            {isMobile && <TableOfContentsDropdown headings={headings} />}
            <div className={styles.headerSection}>
              <h1>CSV Quickstart</h1>
              <p>Fast track your exploratory data analysis by building a dashboard using CSV and google stocks data</p>
            </div>

            <h2 id="setup">Setup</h2>
            <ol>
              <li key="addDataSource">Go to add Data Source</li>
              <div className={styles.imageCard}>
                <img src={addNewConnection} alt="add new connection ui" />
                <hr />
                <p>Grafana - Add new connection UI (screenshot)</p>
              </div>
              <li key="searchCsv">Search &lsquo;CSV&lsquo;</li>
              <div className={styles.imageCard}>
                <img src={csvSearch} alt="searching for csv" />
                <hr />
                <p>Grafana - Search for CSV data source (screenshot)</p>
              </div>
              <li key="downloadCsv">Download CSV Plugin</li>

              <div className={styles.imageCard}>
                <img src={installCsv} alt="install csv" />
                <hr />
                <p id="gettingStarted">Grafana - Install data source (screenshot)</p>
              </div>
            </ol>

            <h2>Getting Started</h2>
            <ol>
              <li key="addUrl">
                <p>
                  In the csv configuration, in the URL field underneath HTTP, add any public dataset that has been
                  hosted online and then click &quot;save and test&quot; at the bottom of the configuration details, you
                  will be prompted with a &quot;success&quot; message and a &quot;data source updated&quot; notification
                  if the url you added is a valid url.
                </p>
                <p>Dataset: </p>
                <pre>https://raw.githubusercontent.com/curran/data/gh-pages/bokeh/GOOG.csv</pre>
                <div className={styles.imageCard}>
                  <img src={configure} alt="configuration panel" />
                  <hr />
                  <p id="buildingDashboard">Grafana - Configuration panel UI (screenshot)</p>
                </div>
              </li>
            </ol>

            <h2>Building a dashboard</h2>
            <ol>
              <li key="selectDashBoards">
                <p>Click on &quot;Dashboards&quot; in the left navigation and select new and then new dashboard</p>

                <div className={styles.imageCard}>
                  <img src={dashboard} alt="dashboard" />
                  <hr />
                  <p>Grafana - New dashboard screen (screenshot)</p>
                </div>
              </li>
              <li key="selectNewPanel">
                <p>
                  You will arrive to an empty dashboard where you will be able to add panels with multiple types of
                  visualizations. Select new panel.
                </p>

                <div className={styles.imageCard}>
                  <img src={newPanel} alt="new panel" />
                  <hr />
                  <p>Grafana - Select new panel (screenshot)</p>
                </div>

                <p>select your CSV data source at the middle left of your screen</p>

                <div className={styles.imageCard}>
                  <img src={panelSelect} alt="panel select" />
                  <hr />
                  <p>Grafana - Panel select (screenshot)</p>
                </div>
                <p>
                  For all the fields/features in your data set, you can select them underneath the fields section and
                  use the &quot;+&quot; and &quot;-&quot;buttons to add and remove fields as you please. Don&lsquo;t for
                  get to add the types of your data for the visualizations to register. We can see our date is a time
                  and the open, close, high, and low are numbers. In the top right corner, we selected the &quot;time
                  series&quot; visualization. and our date range is set from july 2004 to february of 2006 and our
                  fields have different colors indicating each field with our date at the bottom and stock price to the
                  left. You can give your visualization a name and then save and on your home dashboard screen you can
                  see we have an awesome time series viz!
                </p>
                <p>Fields: </p>
                <pre>Date</pre>
                <pre>Open</pre>
                <pre>Close</pre>
                <pre>High</pre>
                <pre>Low</pre>

                <div className={styles.imageCard}>
                  <img src={panelInfo} alt="panel information" />
                  <hr />
                  <p>Grafana - New Panel (screenshot)</p>
                </div>
              </li>
            </ol>
          </>
        </div>
      </div>
    </div>
  );
};
